import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./AssessmentTable.css";

function AssessmentTable(props) {
  const {
    assessments,
    selectAssessmentId,
    setSelectAssessmentId,
    setSelectAssessmentName,
    classDetails,
    gradeLevelOptions,
  } = props;

  const gradeLevel1 = classDetails.grade_level1;
  const gradeLevel2 = classDetails.grade_level2;

  const gradeLevel2Ids = new Set([
    "927b2379-998c-4424-af56-1f82e0144765",
    "9b962947-4f59-4e19-a725-40601b66e8ff",
    "08e97976-f32f-4ad6-a2df-7a2be0789d70",
  ]);

  const assessmentOrder = {
    baseline: 1,
    midpoint: 2,
    "end of year": 3,
  };

  const sortAssessments = (a, b) => {
    const getSortKey = (name) => {
      name = name.toLowerCase();
      if (name.includes("baseline")) return assessmentOrder["baseline"];
      if (name.includes("midpoint")) return assessmentOrder["midpoint"];
      if (name.includes("end of year")) return assessmentOrder["end of year"];
      return 4;
    };

    let aOrder = getSortKey(a.name);
    let bOrder = getSortKey(b.name);
    return aOrder - bOrder;
  };

  // const getCurrentDate = () => new Date();
  // const getDateWithoutYear = (date) =>
  //   new Date(date.getMonth() + 1 + "/" + date.getDate());

  // const isDateInRange = (date, start, end) => {
  //   const currentDate = getDateWithoutYear(date);
  //   const startDate = getDateWithoutYear(new Date(start));
  //   const endDate = getDateWithoutYear(new Date(end));

  //   return currentDate >= startDate && currentDate <= endDate;
  // };

  // const isBaselineEnabled = isDateInRange(getCurrentDate(), "09/01", "12/31");
  // const isMidpointEnabled = isDateInRange(getCurrentDate(), "01/01", "04/30");
  // const isEndOfYearEnabled = isDateInRange(getCurrentDate(), "05/01", "06/30");

  // Helper function to create a date with the current year for consistent comparison
  const getDateWithCurrentYear = (monthDay) => {
    const currentYear = new Date().getFullYear();
    return new Date(`${currentYear}/${monthDay}`);
  };

  // Function to check if the current date is within a specified range
  const isDateInRange = (date, start, end) => {
    const currentDate = new Date(date);
    const startDate = getDateWithCurrentYear(start);
    const endDate = getDateWithCurrentYear(end);

    return currentDate >= startDate && currentDate <= endDate;
  };

  // Check if different assessment periods are enabled based on the current date
  const isMidpointEnabled = isDateInRange(new Date(), "09/01", "12/31");
  const isBaselineEnabled = isDateInRange(new Date(), "01/01", "04/30");
  const isEndOfYearEnabled = isDateInRange(new Date(), "05/01", "06/30");


  // isBaselineEnabled
  console.log("isBaselineEnabled", isBaselineEnabled);
  console.log("isMidpointEnabled", isMidpointEnabled);
  console.log("isEndOfYearEnabled", isEndOfYearEnabled);

  let assessmentsArray = [];

  const gradeAbbreviations = {
    "Standard 1": "S1",
    "Standard 2": "S2",
    Reception: "Recep",
    "Year 1": "Y1",
    "Primary 1": "P1",
    "Primary 2": "P2",
    "Pre-K": "Pre-K",
    "Kindergarten (K)": "K",
    "KG1 (Kindergarten 1)": "KG1",
    "KG2 (Kindergarten 2)": "KG2",
    "Junior Infants": "Jnr",
    "Senior Infants": "Snr",
  };

  let grade1Abbrev = gradeAbbreviations[gradeLevelOptions[0]?.grade1];
  let grade2Abbrev = gradeAbbreviations[gradeLevelOptions[0]?.grade2];

  const replacePrefix = (name) => {
    if (gradeLevel1) {
      return name.replace("Junior", grade1Abbrev || "Grade1");
    } else if (gradeLevel2) {
      return name.replace("Senior", grade2Abbrev || "Grade2");
    }
    return name;
  };

  if (gradeLevel1) {
    assessments.forEach((assessment) => {
      if (!gradeLevel2Ids.has(assessment.id)) {
        let imageName;

        if (assessment.name.toLowerCase().includes("baseline")) {
          imageName = "autumn";
        } else if (assessment.name.toLowerCase().includes("midpoint")) {
          imageName = "spring";
        } else if (assessment.name.toLowerCase().includes("end of year")) {
          imageName = "summer";
        } else {
          imageName = assessment.name.split(" ")[0];
        }

        assessmentsArray.push({
          ...assessment,
          name: replacePrefix(assessment.name, "Junior"),
          imageName: imageName.toLowerCase(),
        });
      }
    });
    assessmentsArray.sort(sortAssessments);
  }

  if (gradeLevel2) {
    assessments.forEach((assessment) => {
      if (gradeLevel2Ids.has(assessment.id)) {
        let imageName;

        if (assessment.name.toLowerCase().includes("baseline")) {
          imageName = "autumn";
        } else if (assessment.name.toLowerCase().includes("midpoint")) {
          imageName = "spring";
        } else if (assessment.name.toLowerCase().includes("end of year")) {
          imageName = "summer";
        } else {
          imageName = assessment.name.split(" ")[0];
        }

        assessmentsArray.push({
          ...assessment,
          name: replacePrefix(assessment.name, "Senior"),
          imageName: imageName.toLowerCase(),
        });
      }
    });
    assessmentsArray.sort(sortAssessments);
  }

  useEffect(() => {
    if (!selectAssessmentId && assessmentsArray.length > 0) {
      let currentAssessment = null;
      if (isBaselineEnabled) {
        currentAssessment = assessmentsArray.find(
          (assessment) => assessment.imageName === "autumn"
        );
      } else if (isMidpointEnabled) {
        currentAssessment = assessmentsArray.find(
          (assessment) => assessment.imageName === "spring"
        );
      } else if (isEndOfYearEnabled) {
        currentAssessment = assessmentsArray.find(
          (assessment) => assessment.imageName === "summer"
        );
      }

      if (currentAssessment) {
        setSelectAssessmentId(currentAssessment.id);
        setSelectAssessmentName(currentAssessment.name);
      }
    }
  }, [
    selectAssessmentId,
    assessmentsArray,
    setSelectAssessmentId,
    setSelectAssessmentName,
    isBaselineEnabled,
    isMidpointEnabled,
    isEndOfYearEnabled,
  ]);

  return (
    <Row className="margin-left">
      <Col>
        <div>
          {assessmentsArray.map((assessment, index) => {
            const isDisabled =
              (assessment.imageName === "autumn" && !isBaselineEnabled) ||
              (assessment.imageName === "spring" && !isMidpointEnabled) ||
              (assessment.imageName === "summer" && !isEndOfYearEnabled);

            return (
              <button
                key={index}
                className={`light-blue-button assessment-button ${
                  selectAssessmentId === assessment.id ? "selected" : ""
                } ${isDisabled ? "disabled" : ""}`}
                onClick={() => {
                  if (!isDisabled) {
                    setSelectAssessmentId(assessment.id);
                    setSelectAssessmentName(assessment.name);
                  }
                }}
              >
                {assessment.name}{" "}
                <img
                  src={`/assets/imgs/${assessment.imageName}-icon.png`}
                  className="season-assessment-icon"
                  alt={assessment.name}
                />
              </button>
            );
          })}
        </div>
      </Col>
    </Row>
  );
}

export default AssessmentTable;
